var cyAppDownLoad = "http://zcom.xazzp.com/APP/APK/%E7%A5%9D%E7%A6%8F%E7%8C%AB%E8%A7%86%E9%A2%91.apk";
var cyAppDownLoadIos = "https://apps.apple.com/cn/app/%E7%A5%9D%E7%A6%8F%E7%8C%AB%E8%A7%86%E9%A2%91/id1593536665";



var wx = require('jweixin-module')
import request from '../api/request';
export default {

  // getConfig("分享标题", "描述", "链接", commonVar.shareImgUrl);
  getConfig(infoTitle, infoDesc = "越早打开越好", infoLink, infoImg) { //自定义分享所需要的参数
      console.log("【分享标题】：", infoTitle);
      console.log("【分享描述】：", infoDesc);
      console.log("【分享路径】：", infoLink);
      console.log("【分享缩略图】：", infoImg);
      // var u = navigator.userAgent;
      var url = window.location.href;
      // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;

      // if (isAndroid) {
      // 	url = window.location.href.split("#")[0]
      // } else {
      // 	url = window.location.href
      // }

      // 去除末尾 #/
      if (url[url.length - 2] == '#') {
        url = url.slice(0, url.length - 2)
      }
      let requestData = {
        domain: "http://www.4796.com",
        // domain: "https://www.4796.com",
        url: window.location.href,  //后端用于拼接请求微信接口
        urlFull: window.location.href, //完整路径，后端未用到
        isWechat:this.isWechat()?1:0
      }
      request.getWechatJsSdk(requestData).then(res => {

        console.log("jssdk接口返回:", res);
        wx.config({
          debug: false,
          appId: res.data.appId,
          timestamp: res.data.timestamp,
          nonceStr: res.data.nonceStr,
          signature: res.data.signature,
          jsApiList: [
            'checkJsApi',
            "hideMenuItems",
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
            'onMenuShareQQ',
            'onMenuShareWeibo',
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'wx-open-launch-weapp'
          ]
        });
        wx.ready(function () {
          wxShareLinks({
            shareTitle: infoTitle,
            shareDesc: infoDesc,
            sharelink: infoLink,
            shareImgUrl: infoImg
          })
          wx.checkJsApi({
            jsApiList: [
              'updateAppMessageShareData',
              'updateTimelineShareData'
            ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success: function (res) {
              wx.updateAppMessageShareData({
                title: infoTitle, // 分享标题
                desc: infoDesc, // 分享描述
                link: infoLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: infoImg, // 分享图标
                success: function () {
                  // 设置成功
                  console.log('分享好友设置成功')
                }
              })
              wx.updateTimelineShareData({
                title: infoTitle, // 分享标题
                link: infoLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: infoImg, // 分享图标
                success: function () {
                  // 设置成功
                  console.log('分享朋友圈设置成功')
                }
              })
            },
            fail: (err) => {
              console.error("checkJsApi报错：", err)
            }
          })
        })
        wx.error(function (err) {
          console.error("wxError报错：", err)
        })
      }).catch(err => { console.error(err) })

      function wxShareLinks(commonVar) {
        wx.onMenuShareAppMessage({
          title: commonVar.shareTitle,
          desc: commonVar.shareDesc,
          link: commonVar.sharelink,
          imgUrl: commonVar.shareImgUrl,
          fail: function (res) {
            alert(JSON.stringify(res))
          }
        });
        wx.onMenuShareTimeline({
          title: commonVar.shareTitle,
          link: commonVar.sharelink,
          imgUrl: commonVar.shareImgUrl,
          fail: function (res) {
            alert(JSON.stringify(res))
          }
        });
        wx.onMenuShareQQ({
          title: commonVar.shareTitle,
          desc: commonVar.shareDesc,
          link: commonVar.sharelink,
          imgUrl: commonVar.shareImgUrl,
          fail: function (res) {
            alert(JSON.stringify(res))
          }
        });
        wx.onMenuShareWeibo({
          title: commonVar.shareTitle,
          desc: commonVar.shareDesc,
          link: commonVar.sharelink,
          imgUrl: commonVar.shareImgUrl,
          fail: function (res) {
            alert(JSON.stringify(res))
          }
        })
      }

  },
  setShareConfig(infoTitle, infoDesc, infoLink, infoImg) {
    wx.ready(function () {
      wx.checkJsApi({
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData'
        ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: function (res) {
          wx.updateAppMessageShareData({
            title: infoTitle, // 分享标题
            desc: infoDesc, // 分享描述
            link: infoLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: infoImg, // 分享图标
            success: function () {
              // 设置成功
              console.log('分享好友设置成功')
            },
            fail: function (err) {
              console.log("设置update1失败！！", err);
            }
          })
          wx.updateTimelineShareData({
            title: infoTitle, // 分享标题
            link: infoLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: infoImg, // 分享图标
            success: function () {
              // 设置成功
              console.log('分享朋友圈设置成功')
            },
            fail: (err) => {
              console.log("设置update失败！", err)
            }
          })
        },
        fail: (res) => {
          console.log("setShare失败！", res)
        }
      })
    })
  },
  isWechat(){
    return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === "micromessenger"
  },
  // 跳转授权页面
  getSkipWxCode(path) {
    // if (!path) {
      path = window.location.href;
    // }
    if(this.isWechat()){
      // let appid = 'wx48c17516aad31d7f' //祝福猫小悦
      let appid = 'wx3b122ff045e3835b'  //好友问候 
      //通过微信官方接口获取code之后，会重新刷新设置的回调地址【redirect_uri】
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(path)}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;
      console.log("跳转登陆：", url);
      // 公众号授权
      window.location.href = url;
    }else{
      // 公众开放平台
      let appid='wx36bd4c64f8879348';
      window.location.href=
      "https://open.weixin.qq.com/connect/qrconnect?appid=" + appid + "&redirect_uri="+encodeURIComponent(path)+"&response_type=code&scope=snsapi_login&state=1#wechat_redirect";
    }


  },

  // setJMLink(){
  //   try{
  //     new JMLink({
  //       jmlink:'https://b7fn6f.jgshare.cn/Aa5r',// 短链地址
  //       button: document.querySelector('#goAppBtn'),
  //       autoLaunchApp : false,
  //       downloadWhenUniversalLinkFailed: true,
  //       inapp : false,
  //       params: {
  //         id:"vKZdnNzg"
  //       }
  //     }); 
  //   } catch(err){console.error(err);}
  // }
}