import request from '../api/request'

var href=location.href;
import VConsole from 'vconsole';
if(href.indexOf('debugger')>-1){
  const vConsole = new VConsole();
}
console.log(333333);

export default{
  _formatTimeText(){
    // 默认显示的文字|
    // 2018/07/26 10:30:30-2018/07/26 11:30:30,第一个时间段文字|
    // 2018/07/27-2018/07/28,第二个时间段文字|
    // 11:00:00-13:00:00,第三个时间段文字
    let cytextlists="你好|00:00:00-00:59:59,深夜好|01:00:00-04:29:59,凌晨好|04:30:00-06:29:59,清晨好|06:30:00-08:59:59,早上好|09:00:00-11:29:59,上午好|11:30:00-12:59:59,中午好|13:00:00-16:59:59,下午好|17:00:00-18:29:59,傍晚好|18:30:00-22:59:59,晚上好|23:00:00-23:59:59,深夜好"
    let textlistsArr = cytextlists.split('|');
    if (textlistsArr.length <= 0) {
    return '';
    }

    // console.log(textlistsArr)

    // 2018/07/26 10:30:30-2018/07/26 11:30:30,第一个时间段文字|
    // 2018/07/27-2018/07/28,第二个时间段文字|
    // 11:00:00-13:00:00,第三个时间段文字
    const jdate = new Date();
    for (let i = 1; i < textlistsArr.length; i++) {
    const customTimeArr = textlistsArr[i].split(',');
    const customTimeStr = customTimeArr[0]; //时间段
    const customTimeImg = customTimeArr[1]; //显示图片地址
    const seTimeArr = customTimeStr.split('-');
    let sTime = seTimeArr[0];
    let eTime = seTimeArr[1];
    if (customTimeStr.indexOf('/') === -1) {
      //只有时间，没有日期
      let nowDate = jdate.getFullYear() + '/' + (jdate.getMonth() + 1) + '/' + jdate.getDate() + ' ';
      sTime = nowDate + sTime;
      eTime = nowDate + eTime;
    }
    sTime = new Date(sTime);
    eTime = new Date(eTime);

    // console.log(sTime)
    // console.log(eTime)

    //当前时间点 在指定的时间段内
    if (jdate.getTime() >= sTime.getTime() && jdate.getTime() <= eTime.getTime()) {
      return customTimeImg;
    }
    }

    return textlistsArr[0];
  },
  formatEllipsis(str = '', limitLen = 24) {
    let 
      len = 0,
      reg = /[\x00-\xff]/, //半角字符的正则匹配
      strs = str.split(''),
      inx = strs.findIndex(s => {
        len += reg.test(s) ? 1 : 2
        if (len > limitLen) return true
      })
    return inx === -1 ? str : str.substr(0, inx) + '...'
  },
  isJSON(str) {
    if (typeof str == 'string') {
        try {
            var obj=JSON.parse(str);
            if(typeof obj == 'object' && obj ){
                return true;
            }else{
                return false;
            }

        } catch(e) {
            return false;
        }
    }
    return false;
  },
  // 转日期格式
  formatTimeToStr(times) {
    function add0(m){return m<10?'0'+m:m }
    //shijianchuo是整数，否则要parseInt转换
    var time = new Date(times);
    var y = time.getFullYear();
    var m = time.getMonth()+1;
    var d = time.getDate();
    var h = time.getHours();
    var mm = time.getMinutes();
    var s = time.getSeconds();
    return y+'-'+add0(m)+'-'+add0(d)+' '+add0(h)+':'+add0(mm)+':'+add0(s);
  },
  /**
   * 订阅消息
   */
  userSubscribe(tmplIds){
    return new Promise((resolve, reject) => {
      //调起客户端小程序订阅消息界面
      wx.requestSubscribeMessage({
        tmplIds: tmplIds,
        success: (res) => {
          let pass=false;
          let ids=[];
          for(var key in res){
            if(res[key]=="accept"){
              pass=true;
              ids.push(key);
            }
          }
          //如果用户同意订阅该条id对应的模板消息
          if (pass) {
            //成功
            // uni.showToast({
            //   title: '订阅成功！',
            //   duration: 1500,
            // })
            resolve(ids)
          } else {
            // uni.showToast({
            //   icon: 'none',
            //   title: '您拒绝了授权，订阅失败！',
            //   duration: 3000,
            // })
            reject();
          }
        },
        fail(err) {
          //失败
          // uni.showToast({
          //   icon: 'none',
          //   title: '订阅失败，请重新点击订阅！',
          //   duration: 3000,
          // })
          reject();
        }
      })
    })
  },
  // 获取设备信息
  getSystemInfo(){
    return new Promise((resolve, reject) => {

      
    })
  },

  //获取页面传递过来的参数
  getPathOptions(){
    var href = this.deCode(window.location.href);
    var options = {};
    try { //获取#/之前的字符串
      var cs=href.split('?')[1]//获取?之后的参数字符串
      cs = cs.split('#')[0]; //去除微信浏览器打开的 # 
      var cs_arr = cs.split('&'); //参数字符串分割为数组
      for (var i = 0; i < cs_arr.length; i++) { //遍历数组，拿到json对象
        options[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1] || ''
      }
    } catch (err) {}
		return options
  },

  // 获取页面路径,不带参数
  getPath(){
    var href = this.deCode(window.location.href);
    return href.split('?')[0];
  },
  //获取当前路径
  getCurrentPath(options){
    var href = this.deCode(window.location.href);
    let op='?';
    for(var key in options){
      op+=key+'='+options[key]+'&'
    }
    if(op=='?'){
      return "";
    }
    op=op.slice(0,-1);
    return href.split('?')[0]+op;
  },

  // 解码
  deCode(str){
    try{
      return decodeURIComponent(str);
    } catch(err){
      return str
    }
  },

  //获取用户信息、openid
  getUserInfo(code){
    return new Promise((resolve, reject) => {
      let userInfo=localStorage.getItem('userInfo')||"{}";
      userInfo=JSON.parse(userInfo);

      if(userInfo.avatar){
        this.userInfo=userInfo;
        resolve(userInfo)
      }else{
        request.getUserConfig({code:code,isWechat:this.isWechat()?1:0}).then(res=>{
          console.log("获取用户信息返回：",res);
          // uni.showToast({
          //   title: "登录成功",
          //   duration: 2000
          // });
          resolve(res.data)
        }).catch(err=>{
          console.error("获取用户信息失败！",err);
          reject(err);
        })
      }
    
    })
  },
	//方法：用来判断是否是微信内置的浏览器
	isWechat() {
		return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === "micromessenger";
	},
  //是否ios系统
  is_ios() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //alert(navigator.userAgent);
        return true;
    } else if (/(Android)/i.test(navigator.userAgent)) {
        return false;
    } else {
        return false;
    }
    ;
  },
  //是否移动端
  isMobile() {
    let userAgentInfo = navigator.userAgent;
    let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    let getArr = Agents.filter(i => userAgentInfo.includes(i));
    return getArr.length ? true : false;
  },
  // 打开app
  openApp(elem){
    if(!this.is_ios()){
      location.href="https://b7fn6f.jgshare.cn/Aa5r";
    }
  },
  //页面携带参数拼接成字符串
  optionsFormat(options){
    let from="";
    for(var key in options){
			from+=`${key}=${options[key]}&`;
		}
		if(from){
      from = from.substring(0, from.length - 1);
    }
    return from
  },
  // 推广统计
	setStatistics(options,page){
    if(page){
      options.page=page
    }
		// 删除公众号附带的参数
		delete options.appmsg_compact_url
		
		let from=this.optionsFormat(options)
		
		request.setFromStatistics({
			setFrom:from
		}).then(res=>{}).catch(err=>{
			console.error("统计报错！");
		});
	},
  // 去除所有空格
  cyTrim(s) {
    return typeof (s) == 'string' ? s.replace(/(^\s+)|(\s+$)/g, '') : '';
  }

}