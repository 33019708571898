var md5 = require('./md5.js');
var aes = require('./aes.js');
import config from '../../config'
const permissions = function () {
  var timestamp = new Date().getTime();
  var _ciphertext = '%cykj*^=#' + timestamp;
  var ciphertext = md5.hexMD5(_ciphertext);  
  return JSON.stringify({
    ciphertext: ciphertext,
    times: timestamp
  })
}

const CryptoJSAesJson = {
  stringify: function (cipherParams) {
    var j = {
      ct: cipherParams.ciphertext.toString(aes.CryptoJS.enc.Base64)
    };
    if (cipherParams.iv) j.iv = cipherParams.iv.toString();
    if (cipherParams.salt) j.s = cipherParams.salt.toString();
    return JSON.stringify(j).replace(/\s/g, '');
  },
  parse: function (jsonStr) {
    var j = JSON.parse(jsonStr);
    var cipherParams = aes.CryptoJS.lib.CipherParams.create({
      ciphertext: aes.CryptoJS.enc.Base64.parse(j.ct)
    });
    if (j.iv) cipherParams.iv = aes.CryptoJS.enc.Hex.parse(j.iv);
    if (j.s) cipherParams.salt = aes.CryptoJS.enc.Hex.parse(j.s);
    return cipherParams;
  }
}
const _aes = function (args, flag, iv) {
  try {
    var encryption, state;
    // // 如果全局变量里有ti
    if (getApp().globalData.keyInfo || getApp().globalData.ti) {
      encryption = getApp().globalData.keyInfo || getApp().globalData.ti;
    } else {
      var ap = config.appId;
      var timezone = 8; //目标时区时间，东八区
      var offset_GMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
      var nowDate = new Date().getTime(); // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
      var targetDate = new Date(nowDate + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000);
      var zeroTime = targetDate.toLocaleDateString() + " 00:00";
      // var ti = new Date(new Date().toLocaleDateString()).getTime();
      var ti = new Date(zeroTime).getTime()
      var lvMkEVR3 = (ap + ti).slice(9, 10 + 1);//下标第九位和第十位
      encryption = lvMkEVR3 + (ap + ti).slice(-9, -9 + 4);//拼接倒数第9位(非下标)至后面四位
      getApp().globalData.ti = encryption;
    }
    if (getApp().globalData.keyInfo) {
      state = 'yun';
    } else {
      state = 'ti';
    }

    var iv = encryption;
    if (flag == 'get') {
      var _get = JSON.parse(aes.CryptoJS.AES.decrypt(JSON.stringify(args), iv, {
        format: CryptoJSAesJson
      }).toString(aes.CryptoJS.enc.Utf8));
      return _get;
    } else {
      var _set = aes.CryptoJS.AES.encrypt(JSON.stringify(args), iv, {
        format: CryptoJSAesJson
      }).toString();
      return _set;
      // return args
    }
  } catch (error) {

  }
}
// 分享随机数参数
const randomOfShare=()=>{  
  try{        
    let args=(Math.ceil(new Date().getTime()/1000)).toString().substring(3,8)
    let random=md5.hexMD5(args).substring(0,11)+args;  
    return random;
  }catch(err){
    return (new Date().getTime()).toString();
  }
}
export default {
  permissions: permissions,
  aes: _aes,
  randomOfShare:randomOfShare
}