const config={
  "wx45d024e80ed87b8b":{
    appletName:"祝福熊人工智能聊天机器人",
    appId:'wx45d024e80ed87b8b',
    shareImg:"http://zcom.xazzp.com/chat/wx45d024e80ed87b8b/share.png",
    touxiang:"chat_zhufuxiong.png",
    quiz_icon:"quiz_icon3.png",
    quiz_bg:"quiz_bg1.png",
  }
}

export default{
  ...config.wx45d024e80ed87b8b, //祝福熊

  host:'http://www.4796.com',
  isOpenEmpower:true, //是否开启授权
  isStatic:true,//伪静态开关

  // 本地开发
  // host:'http://192.168.0.195:8080',
  // isOpenEmpower:false, //是否开启授权
  // isStatic:false,//伪静态开关
}