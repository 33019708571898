import config from '../config'
import encFormat from '../utils/encry/encFormat.js';
import axios from 'axios'//引入axios

const headerEnc={
  'Permissions': encFormat.permissions(),
  'isencry': true,
  'appid': config.appId,
  'key': config.appId||'1548915445202'
}
const header={
  'appid':"wx45d024e80ed87b8b"
}

// const host="https://xgapi.xajjk.com/api"; //正式
// const host="http://yzmchat.kjgn.com/api";
function addOpenid(requestData){
  let userInfo=localStorage.getItem('userInfo')||"{}";
  userInfo=JSON.parse(userInfo);

  console.log('userInfo:',userInfo);
  if(userInfo&&userInfo.openid){
    requestData['openid']=userInfo.openid
    header['openid']=userInfo.openid
  }else if(userInfo){
    localStorage.removeItem('userInfo');
  }
  return requestData
}
export default{
  //发送消息
  sendChat(requestData){
    return new Promise((resolve, reject) => {
      Object.assign(requestData,{appid:config.appId}) 
      requestData=addOpenid(requestData)
      axios({
        // url: "/api/getAppletAskQuestion",
        url: "/api/getTalkWith",
        headers: header,
        method: "POST",
        data: requestData
      }).then(res=>{
        resolve(res)
      }).catch(err=>{
        reject(err)
        console.error(err);
      })
    })
  },
  //上传对话-获取code 等参数
  setTalkInfo(requestData){
    return new Promise((resolve, reject) => {
      requestData=addOpenid(requestData)
      axios({
        url: "/api/getTalkTextGreen",
        headers: header,
        method: "POST",
        data: requestData
      }).then(res=>{
        resolve(res.data)
      }).catch(err=>{
        reject(err)
          console.error(err)
      })
    }).catch(err=>{reject(err);})
  },
  // 获取历史记录
  getHistoryList(requestData){
    return new Promise((resolve, reject) => {
      Object.assign(requestData,{appid:config.appId}) 
      requestData=addOpenid(requestData)
      console.log("获取历史记录上传：",requestData);
      axios({
        url: "/api/getTalkHistory",
        headers: header,
        method: "POST",
        data: requestData
      }).then(res=>{
        resolve(res);
      }).catch(err=>{
        resolve([]);
        console.error("获取对话列表接口报错！",err);
      })
    })
  },
  //删除历史记录对话
  delHistory(requestData){
    return new Promise((resolve, reject) => {
      Object.assign(requestData,{appid:config.appId}) 
      requestData=addOpenid(requestData)
      axios({
        url: "/api/delTalkHistory",
        headers: header,
        method: "POST",
        data: requestData
      }).then(res=>{
        resolve(res)
      }).catch(err=>{
        reject(err)
        console.error("删除失败！",err);
      })
      
    })
  },

  // 上传手机号
  upPhone(requestData){
    return new Promise((resolve, reject) => {
      Object.assign(requestData,{appId:config.appId}) 
      requestData=addOpenid(requestData)
      axios({
        url: "/api/getAppletUser", //测试
        headers: header,
        method: "POST",
        data: requestData
      }).then(res=>{
        resolve(res)
      }).catch(err=>{
        reject(err)
      })
      
    })
  },
  // 判断是否已上传手机号
  getPhoneStart(requestData={}){
    return new Promise((resolve, reject) => {
      Object.assign(requestData,{appId:config.appId})
      requestData=addOpenid(requestData)
      axios({
        url: "/api/getPhoneStart", //测试
        headers: header,
        method: "POST",
        data: requestData
      }).then(res=>{
        resolve(res)
      }).catch(err=>{
        reject(err)
      })
      
    })
  },
  // 对话统计
  appletClick(requestData={}){
    return new Promise((resolve, reject) => {
      Object.assign(requestData,{appid:config.appId}) 
      requestData=addOpenid(requestData)
      console.log("对话统计上传：",requestData);
      axios({
        url: "/api/appletClick",
        // url: "http://ldhapilaravel.xawhz.com//api/appletClick", //测试
        headers: header,
        method: "POST",
        data: requestData
      }).then(res=>{
        resolve(res)
      }).catch(err=>{
        reject(err)
      })
      
    })
  },
  // 推广统计
  setFromStatistics(requestData={}){
    return new Promise((resolve, reject) => {
      this.getOpenid().then(openid=>{
        Object.assign(requestData,{openid:openid,appid:config.appId}) 
        console.log("setFrom统计上传：",requestData);
        axios({
          url: "/api/setFromStatistics",
          headers: header,
          method: "POST",
          data: requestData
        }).then(res=>{
          resolve(res)
        }).catch(err=>{
          reject(err)
        })
      }).catch(err=>{reject(err);})
      
    })
  },
  //获取问题
  getQuizData(requestData={}){
    return new Promise((resolve, reject) => {
      requestData=addOpenid(requestData)
      console.log("获取对话中提问上传参数：",requestData);
      axios({
        url: "/api/getQuestionList",
        headers: header,
        method: "GET",
        params: requestData
      }).then(res=>{
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },
  // 获取精彩提问列表
  getQuizLists(requestData={}){
    return new Promise((resolve, reject) => {
        Object.assign(requestData,{appid:config.appId}) 
        requestData=addOpenid(requestData)
        axios({
          url: "/api/getQuizTextData",
          // url: "/api/getQuizTextList",
          headers: header,
          method: "POST",
          data: requestData,
        }).then(res=>{
          resolve(res);
        }).catch(err=>{
          resolve([]);
            console.error("获取提问列表接口报错！",err);
        })
    })
  },
  // 获取热门文章列表
  getHotArticles(requestData={}){
    return new Promise((resolve, reject) => {
      Object.assign(requestData,{appid:config.appId}) 
      requestData=addOpenid(requestData)
      axios({
        url: "/api/getHotArticles",
        // url: "http://ldhapilaravel.xawhz.com//api/getHotArticles",
        headers: header,
        method: "POST",
        data: requestData
      }).then(res=>{
        resolve(res);
      }).catch(err=>{
        resolve([]);
          console.error("获取提问列表接口报错！",err);
      })
    })
  },
  //获取对话详情及样式
  getTalkDetail(requestData={}){
    return new Promise((resolve, reject) => {
      Object.assign(requestData,{appid:config.appId}) 
      requestData=addOpenid(requestData)
      console.log("详情接口上传：",requestData);
      axios({
        url: "/api/getShareData",
        headers: header,
        method: "POST",
        data: requestData
      }).then(res=>{
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },
  //获取对话详情及样式-用户
  getTalkDetail_user(requestData={}){
    return new Promise((resolve, reject) => {
      Object.assign(requestData,{appid:config.appId}) 
      requestData=addOpenid(requestData)
      console.log("详情接口上传(用户)：",requestData);
      axios({
        url: "/api/getShareIsUserData",
        headers: header,
        method: "POST",
        data: requestData
      }).then(res=>{
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },
  //微信jssdk
  getWechatJsSdk(requestData={}){
    return new Promise((resolve, reject) => {
      console.log("微信jssdk上传：",requestData);
      axios({
        url: "/api/getWechatJsSdk",
        headers: header,
        method: "POST",
        data: requestData
      }).then(res=>{
        resolve(res.data);
      }).catch(err=>{
        reject(err);
      })
    })
  },
  //获取用户信息
  getUserConfig(requestData={}){
    return new Promise((resolve, reject) => {
      console.log("获取用户信息上传：",requestData);
      axios({
        url: "/api/getWechatCallback",
        headers: header,
        method: "GET",
        params: requestData
      }).then(res=>{
        console.log("获取用户信息返回：",res);
        resolve(res.data);
      }).catch(err=>{
        reject(err)
      })
    })
  },
  //上传头像昵称
  upAvatarName(formData={},name="file",path){
    return new Promise((resolve, reject) => {
      requestData=addOpenid(requestData)
      Object.assign(formData,{appid:config.appId}) 
      uni.uploadFile({
        url:"/api/getUserInfo",
        // url:"http://yzmchat.kjgn.com//api/getUserInfo",
        filePath: path,
        name: name,
        formData:formData,
        success: function (res) {
          let result=JSON.parse(res.data);
          if(!result.status){
            uni.hideLoading();
            // uni.showToast({
            //   icon:'none',
            //   title: res.message,
            //   duration: 2000
            // });
            return
          }
          if(result.data&&result.data.avatarUrl){
            let userInfo={avatarUrl:result.data.avatarUrl,nickname:formData.nickName};
            localStorage.setItem('userInfo',JSON.stringify(userInfo))
            resolve(userInfo);
          }else{
            reject('返回数据错误！',res);
          }
        },
        error: function (err) {
          console.error(err);
          reject(err);
        },
      });
    })
  }
}