<template>
  <div class="loadingContainer" style="background:#fff">
    <img src="@/assets/initLoading.gif" style="width: 50%;height:auto;position:absolute;top: 0;bottom: 0; left: 0;right: 0; margin: auto;" alt="">
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
  .loadingContainer {
    width: 100%;
    max-width: 420px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 9997;
    box-sizing: border-box;
    background: #fff;
    /* background: #fff radial-gradient(circle, rgba(254, 172, 94, .5) 0, rgba(196, 114, 206, .5) 70%, rgba(239, 118, 205, .5) 100%) */
  }
</style>